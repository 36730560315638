import React from 'react'

// Components
import Desktop from './Devices/Desktop'


export default function Header() {
    return (
        <Desktop />
    )
}
