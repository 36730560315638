import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Header from '../Utility/Header/Header'
import Footer from '../Utility/Footer/Footer'


export default function Home() {
    return (
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <div className=' bg-primary-light min-h-screen h-full'>
                <Header />
                <div className='text-black mx-page_margin'>
                    <div className='h-[4rem]'></div>
                    <div className='flex flex-col gap-10'>
                        <SubLinks />
                        <FeaturedWork />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

function SubLinks() {
    return (
        <div className='grid grid-cols-4 gap-[4rem]'>
            <div className='w-full h-[250px] bg-black'></div>
            <div className='w-full h-[250px] bg-black'></div>
            <div className='w-full h-[250px] bg-black'></div>
            <div className='w-full h-[250px] bg-black'></div>
        </div>
    )
}

function FeaturedWork() {
    return (
        <div>
            <div>
                <h1 className='font-georgia text-title font-[600] italic leading-none'>featured</h1>
                <h1 className='text-title font-[700] leading-tight'>work</h1>
                <p className='font-semibold text text-black leading'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque.</p>
            </div>
            <div className='w-full h-[800px] bg-black'></div>
        </div>
    )
}
