import React from 'react'

// SVG's
import { ReactComponent as ACDesignLogo } from '../SVG/ACDesignLogo.svg'
import { Link } from 'react-router-dom'

export default function Desktop() {
  return (
    <div className='text font-[700] text-Desktop_Header h-header_height bg-primary-dark flex w-full'>
      <div className='flex flex-col h-full my-auto ml'>
        <div className='w-[10rem] my-auto'>
          <ACDesignLogo className='w-auto' />
        </div>
      </div>
      <div className='grid grid-cols-4 justify-around w-full mr-page_margin my-auto h-fit'>
        <div className='mx-auto'>
          {window.location.pathname === "/" ?
            <>
              <Link to={"/"} className='flex gap-3 p-2'>
                Home
                <div className='rounded-full bg-primary-blue w-[1rem] h-[1rem] my-auto'></div>
              </Link>
            </>
            :
            <div className='flex gap-3'>
              <Link to={"/"} className='text-light_grey p-2'>
                Home
              </Link>
              <div className='bg-transparent w-[1rem] h-[1rem] my-auto'></div>
            </div>
          }
        </div>
        <div className='mx-auto'>
          {window.location.pathname === "/my-work" ?
            <>
              <Link to={"/my-work"} className='flex gap-3 p-2'>
                My Work
                <div className='rounded-full bg-primary-blue w-[1rem] h-[1rem] my-auto'></div>
              </Link>
            </>
            :
            <div className='flex gap-3'>
              <Link to={"/my-work"} className='text-light_grey p-2'>
                My Work
              </Link>
              <div className='bg-transparent w-[1rem] h-[1rem] my-auto'></div>
            </div>
          }
        </div>
        <div className='mx-auto'>
          {window.location.pathname === "/about" ?
            <>
              <Link to={"/about"} className='flex gap-3 p-2'>
                About
                <div className='rounded-full bg-primary-blue w-[1rem] h-[1rem] my-auto'></div>
              </Link>
            </>
            :
            <div className='flex gap-3'>
              <Link to={"/about"} className='text-light_grey p-2'>
                About
              </Link>
              <div className='bg-transparent w-[1rem] h-[1rem] my-auto'></div>
            </div>
          }
        </div>
        <div className='mx-auto'>
          {window.location.pathname === "/contact" ?
            <>
              <Link to={"/contact"} className='flex gap-3 p-2'>
                Contact
                <div className='rounded-full bg-primary-blue w-[1rem] h-[1rem] my-auto'></div>
              </Link>
            </>
            :
            <div className='flex gap-3'>
              <Link to={"/contact"} className='text-light_grey p-2'>
                Contact
              </Link>
              <div className='bg-transparent w-[1rem] h-[1rem] my-auto'></div>
            </div>
          }
        </div>
      </div >
    </div >
  )
}

//font-georgia 