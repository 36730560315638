import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Header from '../Utility/Header/Header'


export default function About() {
    return (
        <>
            <Helmet>
                <title>About</title>
            </Helmet>
            <div className='w-screen min-h-screen bg-black'>
                <Header />
            </div>
        </>
    )
}

