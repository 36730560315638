import React from 'react'
import { Helmet } from "react-helmet";

// Components
import Header from '../Utility/Header/Header'
import Footer from '../Utility/Footer/Footer'

// Images
import BG from './bg.jpg'


export default function Home() {
    return (
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <div className='text bg-black min-h-screen h-full'>
                <Header />
                <div className='relative'>
                    <div className='h-content relative'>
                        <img src={BG} alt='' className='gradient-img h-full w-full' />
                        <div className='absolute top-0 left-0 transform mx-page_margin mt-[6rem]'>
                            <div className='mb-[1.5rem]'>
                                <h1 className='font-georgia text-page_title font-[600] italic leading-[6rem]'>organic</h1>
                                <h1 className='text-page_title font-[700]'>simplicity</h1>
                            </div>
                            <p className='font-semibold text leading'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique nisl vitae volutpat. Nulla aliquet porttitor venenatis. Donec a dui et dui fringilla consectetur id nec massa. Aliquam erat volutpat. Sed ut dui ut lacus dictum fermentum vel tincidunt neque. Sed sed lacinia lectus. Duis sit amet sodales felis. Duis nunc eros, mattis at dui ac, convallis semper risus.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
